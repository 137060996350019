import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../style/GlobalStyle';
import theme from '../../style/theme';
import HeroPanel from '../HeroPanel';
import SEO from '../SEO';
import Container from '../Container';
import MainLayout from './MainLayout';

export default class IELTSSiteLayout extends React.PureComponent {
  render() {
    const { pageName, courseName, showHeroPanel, seo, children } = this.props;

    return (
      <ThemeProvider theme={{ ...theme, isSitePage: true }}>
        <MainLayout hideSideCTA>
          <SEO
            title={seo.title}
            description={seo.description}
            keywords={seo.keywords}
          />
          {showHeroPanel && (
            <Container>
              <HeroPanel courseName={courseName} pageName={pageName} />
            </Container>
          )}
          <Container>{children}</Container>
          <GlobalStyle />
        </MainLayout>
      </ThemeProvider>
    );
  }
}

IELTSSiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  courseName: PropTypes.string,
  showHeroPanel: PropTypes.bool,
  seo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string)
  })
};

IELTSSiteLayout.defaultProps = {
  pageName: '',
  courseName: 'IELTS COURSE',
  showHeroPanel: true,
  seo: {
    title: '',
    description: '',
    keywords: []
  }
};
