import React, { Component } from 'react';

import { Container } from '../../../components/Layout/HelperComponents';
import IELTSSiteLayout from '../../../components/Layout/IELTSSiteLayout';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { media } from '../../../utils/MediaResponsive';
import styled from 'styled-components';
import withI18next from '../../../components/withI18next';

export const query = graphql`
  query {
    gcms {
      seos(where: { path: "about-us/teaching-philosophy" }) {
        path
        title
        description
        keywords
      }
    }
    philosophy1: file(relativePath: { eq: "AboutUs/philosophy1.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    philosophy2: file(relativePath: { eq: "AboutUs/philosophy2.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    philosophy3: file(relativePath: { eq: "AboutUs/philosophy3.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class Philosophy extends Component {
  render() {
    const { data, pageContext } = this.props;
    const seo = data.gcms.seos[0];
    return (
      <IELTSSiteLayout showHeroPanel={false} seo={seo} meta={pageContext.meta}>
        <CenterHeadingWrapper>
          <PageHeadline>Teaching philosophy</PageHeadline>
        </CenterHeadingWrapper>
        <StyledContainer>
          <MainContent>
            <TeachingPhilosophyWrapper>
              <Column>
                <BigText>WHY</BigText>
                <ColumnTop>
                  <ColumnHeader>
                    Think <HighlightText>Students-first</HighlightText>
                  </ColumnHeader>
                  <ColumnHeaderSmall>
                    <QuestionText>Why &bull; </QuestionText> Think
                    Students-first
                  </ColumnHeaderSmall>
                  <ColumnContent>
                    Why we do this? How it helps the students? Does it really
                    help?
                  </ColumnContent>
                </ColumnTop>
                <Img
                  fluid={data.philosophy1.childImageSharp.fluid}
                  alt="childImageSharp"
                />
              </Column>
              <Column>
                <BigText>HOW</BigText>
                <ColumnTop>
                  <ColumnHeader>
                    Find <HighlightText>Smart</HighlightText> solutions
                  </ColumnHeader>
                  <ColumnHeaderSmall>
                    <QuestionText>How &bull; </QuestionText> Find Smart
                    solutions
                  </ColumnHeaderSmall>
                  <ColumnContent>
                    How it can be done more effectively? Is there a different
                    approach to it? Does it challenge what we have known? Does
                    it challenge ourselves?
                  </ColumnContent>
                </ColumnTop>
                <Img
                  fluid={data.philosophy2.childImageSharp.fluid}
                  alt="childImageSharp"
                />
              </Column>
              <Column>
                <BigText>WHAT</BigText>
                <ColumnTop>
                  <ColumnHeader>
                    Stay <HighlightText>simple</HighlightText> and{' '}
                    <HighlightText>straightforward</HighlightText>
                  </ColumnHeader>
                  <ColumnHeaderSmall>
                    <QuestionText>What &bull; </QuestionText> Stay simple &
                    straightforward
                  </ColumnHeaderSmall>
                  <ColumnContent>
                    Are we communicate it to the students in a simple and
                    straightforward way? Is it easy to understand?
                  </ColumnContent>
                </ColumnTop>
                <Img
                  fluid={data.philosophy3.childImageSharp.fluid}
                  alt="childImageSharp"
                />
              </Column>
            </TeachingPhilosophyWrapper>
          </MainContent>
        </StyledContainer>
      </IELTSSiteLayout>
    );
  }
}

const TeachingPhilosophyWrapper = styled.div`
  display: flex;
  padding: 38px 6% 20px;
  ${media.tablet`
    flex-direction: column;
    padding: 0;
 `}
`;

const Column = styled.div`
  flex: 1 1 30%;
  margin: 20px;
  ${media.tablet`
    margin: 0;
    padding: 0 20px;
    background-color: #fff;
 `}
`;

const ColumnTop = styled.div`
  padding: 50px;
  height: 390px;
  background-color: #fff;
  ${media.tablet`
    padding: 20px 0;
    height: auto;
 `}
`;

const ColumnContent = styled.div`
  font-size: 1.5rem;
`;

const ColumnHeader = styled.div`
  font-size: 1.8rem;
  margin-bottom: 50px;
  ${media.tablet`
    display: none;
 `}
`;

const ColumnHeaderSmall = styled.div`
  display: none;
  ${media.tablet`
    display: block;
    background-color: #d22222;
    margin: 10px -20px;
    padding: 20px;
    color: #fff;
 `}
`;

const HighlightText = styled.span`
  color: #d22222;
`;

const BigText = styled.div`
  color: #fff;
  font-size: 10rem;
  margin-bottom: -60px;
  margin-left: -15px;
  ${media.tablet`
    display: none;
 `}
`;

const QuestionText = styled.span`
  font-weight: bold;
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const MainContent = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
`;

const PageHeadline = styled.span`
  font-size: 48px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #2c3841;
  padding: 50px 20px;
  text-align: center;
  text-transform: capitalize;
  ${media.phone`
    font-size: 32px;
   `}
`;

const CenterHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

Philosophy.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      seos: PropTypes.any
    }),
    philosophy1: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.any
      })
    }),
    philosophy2: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.any
      })
    }),
    philosophy3: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.any
      })
    })
  }),
  pageContext: PropTypes.shape({
    meta: PropTypes.any
  })
};

export default withI18next()(Philosophy);
